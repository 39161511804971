<template>
  <a-card class="h-100" :bodyStyle="{ height: '100% !important' }">
    <div class="row">
      <div class="col-3 pr-0">
        <div>
          <p class="m-0 pl-1" id="trim-video-current-slider-value">
            <small
              >Frame: {{ currentSliderVal }} / Time:
              {{
                //duration
                getDuration
              }}</small
            >
          </p>
        </div>
        <div
          class="m-0 pl-1 border progress-container"
          :class="{
            'list-group-item-primary': isStartToTrim,
            'font-weight-bold': isSelected,
          }"
          @click="isSelected = true"
          id="trim-video-select"
        >
          Trim Video
        </div>
      </div>
      <div
        class="pl-0 col-9 pb-3"
        :class="{ 'slider-div': totalFrames > 1800 && zoomIn }"
      >
        <div :style="{ height: '21px', width: getWidth }">
          <Slider
            :tooltips="false"
            v-model="currentSliderVal"
            :min="0"
            :max="totalFrames"
            :classes="{
              target: 'slider-target',
              touchArea: 'slider-touch-area',
            }"
            id="current-video-slider"
          />
        </div>
        <div
          class="p-0 m-0 border progress-container d-flex"
          :style="{ width: getWidth }"
        >
          <painter-component
            v-for="(interval, index) in painter"
            :key="index"
            :interval="interval"
            :prevEnd="getPrevEnd(painter, index)"
            :index="index"
            @handleRemoveVideo="handleRemoveVideo"
          >
          </painter-component>
        </div>
      </div>
    </div>
  </a-card>
  <!-- <div class="row d-flex justify-content-end action-button">
          <div class="col-2" v-if="!videoInfo?.isProtectedVideo">
            <button
              class="btn btn-primary btn-block d-flex justify-content-center"
              @click="handleSave"
              :disabled="loading"
            >
              <spinner-component
                v-if="loading"
                :height="25"
                :width="25"
              ></spinner-component>
              <span v-else>Trim Video</span>
            </button>
          </div>
        </div> -->
</template>
<script>
import PainterComponent from './Painter.vue';
import SpinnerComponent from '../../../../shared/Components/Spinner.vue';
import Slider from '@vueform/slider';
import '@vueform/slider/themes/default.css';
import getTime from '../../../../shared/Helpers/getTime';
import { actions, queues } from 'src/config/long-running-task-config';
import { mapGetters } from 'vuex';

export default {
  components: {
    PainterComponent,
    SpinnerComponent,
    Slider,
  },
  inject: ['toast'],
  props: [
    'videoInfo',
    'taskId',
    'taskName',
    'zoomIn',
    'totalFrames',
    'framesPerPixel',
    'videoMetaData',
  ],
  emits: [
    'onTrim',
    'frameChange',
    'zoomChange',
    'videosTrimmed',
    'changeCurrentVideoStatus',
  ],
  data: function() {
    return {
      isSelected: false,
      isStartToTrim: false,
      painter: [],
      duration: '00:00',
      currentSliderVal: 0,
      startingFrame: 0,
      intervalIndex: -1,
      debounce: null,
      autoSaveTime: 2,
      key: '',
    };
  },

  computed: {
    ...mapGetters(['organization']),
    getWidth() {
      if (this.totalFrames > 1800) {
        return this.zoomIn
          ? `${this.totalFrames / this.framesPerPixel}px`
          : '100%';
      }
      return '100%';
      // return this.totalFrames > 1800 ? `${this.totalFrames}px` : "100%";
    },

    getDuration() {
      if (!this.videoMetaData) return '00:00:00';
      const seconds = this.currentSliderVal / this.videoMetaData?.frameRate;
      return getTime(seconds * 1000);
    },
  },

  created() {
    const userId = localStorage.getItem('id');
    this.key = `${userId}-${this.videoInfo?.fileName}`;
    var self = this;
    document.addEventListener('keydown', function(evt) {
      if (evt.code === 'Space') {
        evt.preventDefault();
        if (self.isSelected && self.isStartToTrim) {
          self.isSelected = false;
          self.isStartToTrim = false;
          self.startingFrame = 0;
          // self.currentSliderVal++;
        } else if (self.isSelected) {
          self.setIntervalToTrim();
          self.selectToTrim();
          // self.startingFrame = self.currentSliderVal;
          // self.setStartingPoint();
        }
      }
    });
  },

  mounted() {
    this.emitter.on('clear-trimming', this.clearTrimming);
    this.emitter.on('zoom-in-out', this.handleZoomInOut);
    this.emitter.on('trim-video', this.handleSave);
    this.emitter.on('remove-video', this.handleRemoveVideo);
    this.duration = this.videoInfo?.duration;

    this.retrieveProgress();
  },

  beforeUnmount() {
    this.emitter.off('clear-trimming', this.clearTrimming);
    this.emitter.off('zoom-in-out', this.handleZoomInOut);
    this.emitter.off('trim-video', this.handleSave);
    this.emitter.off('remove-video', this.handleRemoveVideo);
  },

  watch: {
    currentSliderVal: function(value) {
      this.handlePainterChange(value);
      this.setDebounce();
    },
    painter: function(value) {
      this.updateTrimmingInfo();
      this.setDebounce();
    },

    isStartToTrim: function(value) {
      this.$emit('changeCurrentVideoStatus', value, this.intervalIndex);
    },
    totalFrames() {
      this.updateTrimmingInfo();
    },
  },

  methods: {
    updateTrimmingInfo() {
      const updatedInfo = this.painter.map((el) => ({
        start: Math.round((el.start * this.totalFrames) / 100),
        end: Math.round((el.end * this.totalFrames) / 100),
      }));
      this.$emit('videosTrimmed', updatedInfo);
    },

    handlePainterChange(currentSliderValue) {
      this.$emit('frameChange', currentSliderValue);
      if (!this.isSelected || !this.isStartToTrim) return;
      if (currentSliderValue <= this.startingFrame && this.painter.length > 0) {
        return;
      }
      const end = (currentSliderValue / this.totalFrames) * 100;
      const temp = [...this.painter];
      const nextIntervalStart = temp[this.intervalIndex + 1]?.start;
      if (nextIntervalStart && end >= nextIntervalStart) {
        return;
      }
      // const lastIndex = temp.length - 1;
      // temp[lastIndex].end = end;
      // this.painter = temp;
      temp[this.intervalIndex].end = end;
      this.painter = temp;
    },

    setIntervalToTrim() {
      const currSliderPercent = this.getPercent(this.currentSliderVal);
      for (let idx = 0; idx < this.painter.length; idx++) {
        const { start } = this.painter[idx];
        if (currSliderPercent < start) {
          this.intervalIndex = idx;
          return;
        }
      }
      this.intervalIndex = this.painter.length;
    },

    selectToTrim() {
      if (!this.isSelected) return;
      this.startingFrame = this.currentSliderVal;
      const currentSliderPercent = this.getPercent(this.currentSliderVal);

      if (this.isOverLapped(currentSliderPercent)) {
        this.toast.info('Please remove the overlapped video first!', {
          timeout: 3000,
        });
        return;
      }
      const intervalToBeAdded = this.painter[this.intervalIndex];
      if (
        intervalToBeAdded &&
        intervalToBeAdded?.start === intervalToBeAdded?.end
      )
        return;
      this.isStartToTrim = true;
      const obj = {
        start: currentSliderPercent,
        end: currentSliderPercent,
      };
      // update Painter
      let tempPainter = [...this.painter, obj].sort(
        (a, b) => a.start - b.start
      );
      this.painter = tempPainter;
    },

    // setStartingPoint() {
    //   const start = this.getPercent(this.startingFrame);
    //   if (this.isOverLapped(start)) {
    //     this.toast.info('Please remove the overlapped video first!', {
    //       timeout: 3000,
    //     });
    //     return;
    //   }
    //   this.isStartToTrim = true;
    //   const interval = { start, end: 0 };
    //   const lastItem = this.painter[this.painter.length - 1];
    //   if (lastItem?.end === 0) {
    //     this.painter.pop();
    //   }
    //   this.painter.push(interval);
    //   this.backwardFlag = false;
    // },

    setDebounce() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(
        this.handleAutoSaveProgress,
        this.autoSaveTime * 1000
      );
    },

    handleAutoSaveProgress() {
      const data = {
        currentSliderVal: this.currentSliderVal,
        painter: this.painter,
      };
      localStorage.setItem(this.key, JSON.stringify(data));
    },

    retrieveProgress() {
      const data = localStorage.getItem(this.key);
      if (data) {
        const { painter, currentSliderVal } = JSON.parse(data);
        this.painter = painter;
        this.currentSliderVal = currentSliderVal;
      }
    },

    handleSave() {
      const intervals = this.getInrevals();
      const payload = {
        queueName: queues.longRunningTask,
        sqsMessage: {
          task_id: this.taskId,
          organization: this.organization,
          name: this.taskName,
          action: actions.trimVideo,
          bucket_name: `${this.organization}-training`,
          intervals: intervals,
          path_to_video: `${this.taskName}/Videos/${this.videoInfo?.fileName}`,
        },
      };
      this.$emit('onTrim', payload);
    },

    getInrevals() {
      return this.painter.map((el) => ({
        start: this.getFrame(el.start) / this.videoMetaData.frameRate,
        end: this.getFrame(el.end) / this.videoMetaData.frameRate,
        startFrame: this.getFrame(el.start),
        endFrame: this.getFrame(el.end),
      }));
    },

    getFrame(percent) {
      if (percent === undefined) {
        return 0;
      }
      return Math.round((percent * this.totalFrames) / 100);
    },

    isOverLapped(currentVideoStart) {
      return this.painter.some(
        (v) => v.start <= currentVideoStart && currentVideoStart <= v.end
      );
    },

    addToPainter({ startFrame, endFrame }) {
      const temp = [...this.painter];
      temp.push({
        start: this.getPercent(startFrame),
        end: this.getPercent(endFrame),
      });
      this.painter = temp;
      this.currentSliderVal = endFrame;
    },

    getPercent(frame) {
      return (frame / this.totalFrames) * 100;
    },

    getPrevEnd(list, index) {
      let idx = index - 1 < 0 ? 0 : index - 1;
      return list[idx].end;
    },

    handleRemoveVideo(index) {
      this.painter = this.painter.filter((_, idx) => index !== idx);
      this.isSelected = false;
      this.isStartToTrim = false;
      this.startingFrame = 0;
    },

    clearTrimming() {
      this.painter = [];
      this.currentSliderVal = 0;
      this.isSelected = false;
      this.isStartToTrim = false;
      localStorage.removeItem(this.key);
    },

    undoLastTrim() {
      const temp = [...this.painter];
      temp.pop();
      this.painter = temp;
    },
  },
};
</script>
<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}

.border {
  border: 1px solid;
}

.slider {
  outline: none;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.progress-container {
  height: 26px;
}

.slider-div {
  overflow-x: scroll;
  overflow-y: hidden;
}

.slider-target {
  /* margin-bottom: 1em; */
}

.slider-touch-area {
  background: gray;
  border-radius: 50%;
}

.painter {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 98% !important;
}

.action-button {
  margin-top: 0.5% !important;
}

.font-weight-bold {
  font-weight: bold !important;
}
</style>
