<template>
  <div
    class="progress-container"
    :style="{ width: whiteWidth + '% !important' }"
  />
  <a-dropdown
    overlayClassName="overlayClass"
    placement="topCenter"
    :trigger="['contextmenu']"
  >
    <div
      class="progress-container"
      :style="{
        background: 'lightblue',
        width: interval.end - interval.start + '% !important',
      }"
      :id="'trim-interval-' + index"
    />
    <template #overlay>
      <a-menu :id="'trim-interval-' + index + '-menu'">
        <a-menu-item
          @click="$emit('handleRemoveVideo', index)"
          :id="'delete-trim-interval-' + index"
        >
          Remove this video
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
export default {
  data: function() {
    return {
      log: console.log,
      showContext: false,
    };
  },
  emits: ['handleRemoveVideo'],
  props: ['interval', 'prevEnd', 'index'],
  computed: {
    whiteWidth() {
      return this.interval.start - this.prevEnd <= 0
        ? this.interval.start
        : this.interval.start - this.prevEnd;
    },
  },
};
</script>
<style scoped>
.progress-container {
  height: 26px;
}
.overlayClass {
  z-index: 224300000;
}
</style>
